<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();
const { $i18n, $router } = useNuxtApp();
const storyblokApi = useStoryblokApi();
const storyblokApiOptions = useStoryblokApiOptions();
const utils = useUtils();
const { data, fetchConfig } = useConfig();

const websiteConfig = useState("config");
const darkMode = useDarkLightSwitch();
const { locale, locales } = useI18n();
const header = ref(null);
const headerMenu = ref(null);

const localePath = useLocalePath();
const { showSchedulePopup } = useSchedule();
const { findLinksWithId, goto } = useUtils();
const { isSolutionRootPage } = await useProducts();

const { $store } = useNuxtApp();
const storyblokLinks = computed(() => $store.getters.getLinks);
const allStories = ref([]);

const loaded = ref(false);
const visibleSubmenu = ref(false);
const updateMenu = (locale) => {
  const { data } = websiteConfig.value;
  headerMenu.value = data.story.content.header_menu;
  headerMenu.value = headerMenu.value.map((link) => {
    link = utils.mapLinks(link, storyblokLinks.value, locale);
    return {
      ...link,
      subItems: subItems(link),
    };
  });
};

const story = computed(() => $store.getters.getStory);

const switchLanguage = async (locale) => {
  $i18n.setLocale(locale);
  await fetchConfig(locale);
  await loadSubStories(locale);
  updateMenu();
};

// Detect Browser Language
if ($router.path === "/" && $i18n.getBrowserLocale() !== locale.value) {
  const locale = $i18n.getBrowserLocale();
  switchLanguage(locale);
  $router.push(switchLocalePath(locale));
}
const closeMobileMenu = () => {
  mobileMenu.value.status = 2;
  loaded.value = true;

  mobileMenu.value.status = 0;
  mobileMenu.value.isActive = mobileMenu.value.status > 0;

  setTimeout(() => {
    mobileMenu.value.visible = false;
  }, 500);
  changeNavBg();
};
const openMobileMenu = () => {
  mobileMenu.value.visible = true;
  mobileMenu.value.status = 1;
};
const toggleMobileMenu = () => {
  mobileMenu.value.status++;
  mobileMenu.value.visible = true;

  if (mobileMenu.value.status === 2) {
    closeMobileMenu();
  } else if (mobileMenu.value.status > 2) {
    mobileMenu.value.status = 0;
  }

  mobileMenu.value.isActive = mobileMenu.value.status > 0;
  changeNavBg();
};

const mobileMenu = ref({
  status: 0,
  isActive: false,
  visible: false,
});

const showMobileMenu = computed(() => display.width.value < 1000);

const navWhite = computed(() => $store.getters.navWhite);

const parentStory = computed(() => {
  return story.value?.parent_id ? findLinksWithId(story.value.parent_id) : null;
});

function getStoryByUuid(uuid) {
  return allStories.value.find((story) => story.uuid === uuid);
}

function changeNavBg() {
  const nav = document.querySelector(".nav-bg");
  const scrollTop = window.scrollY;
  const maxScroll = 100;
  const opacity = mobileMenu.value.isActive
    ? 0.8
    : Math.min((scrollTop / maxScroll) * 0.4, 0.4);
  const borderRadius = showMobileMenu.value
    ? 0
    : Math.min((scrollTop / maxScroll) * 14, 14);
  const maxBorderRadius = showMobileMenu.value ? 0 : 14;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const navWidth = screenWidth >= 1440 ? 1350 : 1170;

  nav.style.backgroundColor = `rgba(0, 0, 0, ${0.1 + opacity})`;
  // if (story.value?.hasSubnav && scrollTop > 100) {
  //   visibleSubmenu.value = true;
  // } else {
  //   visibleSubmenu.value = false;
  // }
  if (scrollTop > 100) {
    nav.style.borderBottomLeftRadius = `${maxBorderRadius - borderRadius}px`;
    nav.style.borderBottomRightRadius = `${maxBorderRadius - borderRadius}px`;
    nav.style.maxWidth = "100%";
  } else {
    nav.style.borderBottomLeftRadius = `${maxBorderRadius}px`;
    nav.style.borderBottomRightRadius = `${maxBorderRadius}px`;
    nav.style.maxWidth = `${navWidth}px`;
  }
}

function initOnScrollListener() {
  window.addEventListener("scroll", changeNavBg);
}

onMounted(async () => {
  changeNavBg();
  initOnScrollListener();
  await loadSubStories();
  updateMenu();

  window.addEventListener("resize", () => {
    changeNavBg();
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    changeNavBg();
  });
});

const visibleSubNav = ref(null);
const hideSubnav = _debounce(() => {
  visibleSubNav.value = false;
}, 400);

async function loadSubStories() {
  const { data } = await storyblokApi.get("cdn/stories", {
    ...storyblokApiOptions,
    language: locale.value,
    filter_query: {
      component: {
        in: "page,use-case,success-story,product",
      },
    },
    per_page: 100,
  });

  allStories.value = data.stories;
}
function mapSubItems(key) {
  return {
    ...utils.mapLinks(
      storyblokLinks.value[key],
      storyblokLinks.value,
      locale.value,
    ),
    _blok: allStories.value.find((story) => story.uuid === key),
  };
}

function subItems(blok) {
  let items = [
    ...(
      blok?.sub_items?.map((link) =>
        utils.mapLinks(link, storyblokLinks.value, locale.value),
      ) || []
    ).map((link) => mapSubItems(link.link.id)),
  ];

  const rootItemKey = Object.keys(storyblokLinks.value).find(
    (key) => key === blok.link.id,
  );

  if (!items.length && rootItemKey) {
    const rootItem = storyblokLinks.value[rootItemKey];
    items = Object.keys(storyblokLinks.value)
      .filter(
        (key) =>
          !storyblokLinks.value[key].is_startpage &&
          storyblokLinks.value[key].parent_id === rootItem.parent_id &&
          rootItem.parent_id !== 0,
      )
      .map(mapSubItems);

    if (isSolutionRootPage(blok)) {
      // Verwendung von splice, um ein Element nach dem 2. Element (Index 2) einzufügen
      // items.splice(2, 0, { navTitle: "Lösungen" });
    }
  }

  return items;
}
</script>

<template>
  <div
    v-if="false && showMobileMenu"
    class="sticky-mainnav mobile-menu"
    :class="{ active: mobileMenu.isActive, close: mobileMenu.status === 2 }"
  >
    <nav
      v-if="headerMenu"
      role="navigation"
      class="flex h-full text-center items-center"
    >
      <ul class="space-y-5">
        <li v-for="blok in headerMenu" :key="blok._uid">
          <NuxtLink
            :to="localePath(`/${blok.full_path}`)"
            class="main-navigation-item"
            @click="closeMobileMenu"
          >
            {{ blok.name }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
  <header
    class="nav-main"
    :class="{
      'sticky-mainnav': true,
      '_mobile-menu-active': showMobileMenu && mobileMenu.isActive,
    }"
    :data-animate="showMobileMenu ? null : fadeInDown"
    data-animate-delay="3"
  >
    <div class="nav-rounded-center w-nav">
      <div class="nav-bg-holder">
        <div class="nav-bg"></div>
      </div>
      <div class="container nav-container">
        <div class="nav-menu-parent-rounded-center gap-2">
          <NuxtLink
            :to="localePath('/')"
            class="brand-nav w-nav-brand md:mr-2 mr-auto"
          >
            <span>zweikern</span>
            <div class="dark-logo in-colors"></div>
            <div class="white-logo"></div>
          </NuxtLink>

          <template v-if="showMobileMenu">
            <div class="menu-button menu-click-area mobile-lang-switch">
              <MenuLanguageSwitcher @change="switchLanguage" />
            </div>

            <button
              class="menu-button w-nav-button menu-click-area"
              @click.stop="showSchedulePopup()"
            >
              <v-icon icon="mdi mdi-phone" />
            </button>

            <button
              class="menu-button w-nav-button menu-click-area"
              @click.stop="toggleMobileMenu"
            >
              <v-icon>
                <img
                  src="/menu-icon.svg"
                  loading="lazy"
                  alt="Mobile Menu Toggle"
                  class="menu-icon"
                />
              </v-icon>
            </button>
          </template>

          <nav v-else-if="headerMenu" role="navigation" class="nav-menu">
            <v-chip
              v-if="visibleSubmenu"
              class=""
              color="primary"
              variant="outlined"
            >
              {{ parentStory?.name }}
            </v-chip>
            <div v-show="visibleSubmenu" id="sticky-sub-menu"></div>
            <div>
              <ul v-show="!visibleSubmenu" class="flex gap-0">
                <li
                  v-for="blok in headerMenu"
                  :key="blok._uid"
                  class="nav-link-holder"
                >
                  <MyLink
                    :link="
                      !blok?.sub_items
                        ? { linktype: 'story', url: blok.full_path }
                        : { linktype: 'url', url: '@' }
                    "
                    class="nav-link"
                    :class="{
                      'no-link': blok?.sub_items?.length,
                      'no-submenu': !blok.subItems.length,
                    }"
                    @mouseover="
                      hideSubnav.cancel();
                      visibleSubNav = blok._uid;
                    "
                    @mouseleave="hideSubnav()"
                    @click="hideSubnav()"
                  >
                    {{ blok.name }}
                    <div class="link-background"></div>
                    <v-icon
                      v-if="blok.subItems.length"
                      icon="mdi mdi-chevron-down"
                    />
                  </MyLink>
                  <DropDownSolutionsNav
                    v-if="blok.subItems.length && isSolutionRootPage(blok)"
                    v-show="visibleSubNav === blok._uid"
                    :parent="blok"
                    :all-stories
                    grid
                    :items="blok.subItems"
                    @mouseover="hideSubnav.cancel()"
                    @mouseleave="hideSubnav()"
                    @click="hideSubnav()"
                  />
                  <DropDownNav
                    v-else-if="
                      blok.subItems.length && !isSolutionRootPage(blok)
                    "
                    v-show="visibleSubNav === blok._uid"
                    :parent="blok"
                    :all-stories
                    :items="blok.subItems"
                    @mouseover="hideSubnav.cancel()"
                    @mouseleave="hideSubnav()"
                    @click="hideSubnav()"
                  />
                </li>
              </ul>
            </div>
            <ul class="nav-main-buttons-wrap">
              <li v-if="false" class="nav-link-holder">
                <NuxtLink to="/shop" class="nav-link">
                  <span class="mdi mdi-basket"></span>
                  <div class="link-background"></div>
                </NuxtLink>
              </li>

              <li class="nav-link-holder">
                <a
                  href="https://analytics.zweikern.com/"
                  target="_blank"
                  class="nav-link w-inline-block"
                >
                  <span class="mdi mdi-login"></span>
                  <div class="link-background"></div>
                </a>
              </li>

              <li class="nav-link-holder nav-button-holder">
                <SalesContactButton class="nav-button" no-icon />
              </li>

              <li class="nav-link-holder ml-2">
                <MenuLanguageSwitcher @change="switchLanguage" />
              </li>
            </ul>
          </nav>

          <div
            v-if="false"
            :class="{
              'theme-light': !darkMode,
              'theme-dark': darkMode,
            }"
            class="bg-themeBackground text-themeText p-5"
          >
            <div @click="darkMode = !darkMode">darkMode</div>
            <!-- <Toggle v-model="darkMode" off-label="Light" on-label="Dark" /> -->
          </div>
        </div>
      </div>

      <div
        v-if="showMobileMenu && mobileMenu.visible"
        class="mobile-nav-wrap w-nav-overlay w-100"
      >
        <nav
          role="navigation"
          class="nav-menu w-nav-menu slide-in-menu"
          :class="{
            out: loaded && !mobileMenu.isActive,
            in: mobileMenu.isActive,
          }"
        >
          <ul class="w-100">
            <li
              v-for="blok in headerMenu"
              :key="blok._uid"
              class="nav-link-holder"
            >
              <MyLink
                v-if="!blok.subItems.length"
                :link="
                  !blok?.sub_items
                    ? { linktype: 'story', url: blok.full_path }
                    : { linktype: 'url', url: '@' }
                "
                class="nav-link"
                :class="{
                  'no-link': blok?.sub_items?.length,
                  'no-submenu': !blok.subItems.length,
                }"
                @mouseover="
                  hideSubnav.cancel();
                  visibleSubNav = blok._uid;
                "
                @mouseleave="hideSubnav()"
                @click.stop="console.log('foo')"
              >
                {{ blok.name }}
                <div class="link-background"></div>
                <v-icon
                  v-if="blok.subItems.length"
                  icon="mdi mdi-chevron-down"
                />
              </MyLink>
              <v-btn
                v-else
                class="nav-link"
                :class="{
                  'no-link': blok?.sub_items?.length,
                  'no-submenu': !blok.subItems.length,
                }"
                @click="
                  if (visibleSubNav === blok._uid) {
                    hideSubnav();
                  } else {
                    hideSubnav.cancel();
                    visibleSubNav = blok._uid;
                  }
                "
                @dblclick="goto(blok.full_path)"
              >
                {{ blok.name }}
                <div class="link-background"></div>
                <v-icon
                  v-if="blok.subItems.length"
                  icon="mdi mdi-chevron-down"
                />
              </v-btn>
              <DropDownNav
                v-if="blok.subItems.length"
                v-show="visibleSubNav === blok._uid"
                :parent="{ ...blok, _blok: getStoryByUuid(blok.link.id) }"
                with-overview
                :items="blok.subItems"
                @mouseover="hideSubnav.cancel()"
                @mouseleave="hideSubnav()"
                @click="hideSubnav(), closeMobileMenu()"
              />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<!-- <style src="@vueform/toggle/themes/default.css"></style> -->
<style lang="scss" scoped>
.nav-menu {
  position: static;
}
.mobile-nav-wrap {
  position: absolute;
  left: 0;
  top: 60px;
  overflow: hidden;
}

.slide-in-menu {
  position: relative;
  z-index: 1;
  padding-bottom: 20px;
  transform: translate3d(
    0,
    -260px,
    0
  ); /* Menü außerhalb des Sichtbereichs starten */
  animation-timing-function: ease-in-out;

  &.in {
    animation-name: slide-in-menu;
    animation-duration: 400ms;
    animation-fill-mode: forwards; /* Behalte den Endzustand nach der Animation bei */
  }

  &.out {
    animation-name: slide-out-menu;
    animation-duration: 400ms;
    animation-fill-mode: forwards; /* Behalte den Endzustand nach der Animation bei */
  }
}

@keyframes slide-in-menu {
  0% {
    transform: translate3d(
      0,
      -260px,
      0
    ); /* Menü beginnt außerhalb des Sichtbereichs */
  }
  100% {
    transform: translate3d(
      0,
      0,
      0
    ); /* Menü wird in den sichtbaren Bereich geschoben */
  }
}

@keyframes slide-out-menu {
  0% {
    transform: translate3d(0, 0, 0); /* Menü ist sichtbar */
  }
  100% {
    transform: translate3d(
      0,
      -260px,
      0
    ); /* Menü wird aus dem sichtbaren Bereich geschoben */
  }
}

.nav-bg {
  background-color: rgba(0, 0, 0, 0.4);
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(1170px + 0px);
  border-radius: 14px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 600ms;
}

@media screen and (min-width: 1440px) {
  .nav-bg {
    max-width: calc(1350px + 0px);
  }
}

.mobile-lang-switch {
  min-width: 42px;
  width: auto;
}
.brand-nav {
  width: 55px;
  flex: none;
  align-items: center;
  padding-left: 0;
  display: flex;
  overflow: hidden;

  span {
    text-indent: -100px;
  }

  &:hover {
    .dark-logo {
      opacity: 1;
    }
    .white-logo {
      opacity: 0;
    }
  }
}

.dark-logo,
.white-logo {
  transition: all 600ms ease-in-out;
}

.dark-logo {
  margin-top: 0;
  position: absolute;
  transition: all 600ms ease-in-out;

  &.in-colors {
    width: 36px;
    height: 36px;
    max-height: 36px;
    opacity: 0;
    background-image: url("@/assets/images/Logo-Color.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 6px;
    display: block;
  }
}

.white-logo {
  width: 36px;
  height: 36px;
  max-height: 36px;
  opacity: 1;
  object-fit: fill;
  -o-object-fit: fill;
  background-image: url("@/assets/images/zweikern-trans-solo.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.menu-click-area {
  position: relative;
  z-index: 10000;
}
.mobile-menu {
  overflow: hidden;
  align-items: center;
  background-color: transparent;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  transition:
    background-color 400ms cubic-bezier(0.4, 0, 0.2, 1),
    width 400ms ease,
    opacity 400ms linear;
  width: 0vw;
  height: 0vh;
  max-width: 100%;
  opacity: 0;

  a,
  button {
    color: inherit;
    border-bottom: 1px solid transparent;
  }

  a.main-navigation-item {
    &.router-link-active,
    &:hover {
      border-bottom-color: #fff;
    }
  }
}
.active.mobile-menu {
  background-color: #2e373ff1;
  backdrop-filter: blur(5px);
  opacity: 1;
  width: 100vw;
  height: 100vh;
}
.active.close.mobile-menu {
  width: 0vw;
  opacity: 0;
}

ul {
  margin: 0;
}
.main-nav {
  background-color: #0a0c0d25;
  backdrop-filter: blur(5px);
  transition:
    all 400ms ease,
    height 600ms ease;
  height: 50px;

  border-bottom: 1px solid transparent;

  .inside {
    position: relative;
    z-index: 10000;
  }

  .dark-logo.in-colors {
    transition: all 200ms linear;
    opacity: 1;
  }

  a,
  button {
    color: #fff;

    &.main-navigation-item {
      border-bottom: 1px solid transparent;
      margin-top: 1px;
    }

    &.main-navigation-item {
      &.router-link-active,
      &:hover {
        border-bottom-color: #a2027b;
      }
    }
  }

  &:not(.white) {
    background-color: #0a0c0d25;
    height: 50px;

    .dark-logo.in-colors {
      opacity: 0;
    }

    a,
    button {
      color: #fff;
    }

    a.main-navigation-item {
      &.router-link-active,
      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 0;
}

.link-background {
  opacity: 0;
  margin: 2px;
  transition: all 200ms linear;
}
a,
button {
  &:hover {
    .link-background {
      opacity: 1;
      margin: 0px;
    }
  }
}
</style>

<style lang="scss">
a.nav-link.no-submenu {
  padding-top: 10px;
}
</style>
